body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Rubik", sans-serif;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#landing-splash {
  height: 100vh;
  width: 100vw;
  font-size: 20px;
  margin: 0px;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("glitch.jpg");
}

h1 {
  margin-bottom: 0;
  margin-top: 60px;
}

img {
  width: 50%;
}

p {
  margin-bottom: 60px;
  margin-top: 20px;
}

a {
  padding: 40px;
  background-color: goldenrod;
  border: 1px solid goldenrod;
  color: #fff;
  border: 0;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  border: 1px;
}

a:hover {
  background-color: gold;
  font-size: 25px;
  padding: 37px 32px;
}
